<template>
    <div class="ReceiveEdit" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-form
            @submit.native.prevent
            :inline="true"
            :model="form"
            :rules="rules"
            ref="editForm"
            label-width="80px"
            size="small"
        >
            <el-card shadow="never" style="margin-top: 8px; text-align: left">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构类型">
                            <el-input :value="formExtend.deptTypeName" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-input :value="formExtend.deptName" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="内购人">
                            <el-input v-model="formExtend.receiveName" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input :value="formExtend.creator" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建日期">
                            <el-input :value="formExtend.createTime" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="查询条件">
                            <el-input v-model="search" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <ef-remark v-model="form.remark" />
                    </el-col>
                </el-row>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px; text-align: left">
                <el-button type="primary" :disabled="disableQueryAndAddButton" @click="queryAndAdd" size="small">
                    查询并添加
                </el-button>
                <el-button
                    type="primary"
                    :disabled="disableShowPickUpGoodsDialog"
                    @click="showPickUpGoods"
                    size="small"
                >
                    选择商品基本信息
                </el-button>
                <el-button
                    type="primary"
                    :loading="saveLoading"
                    :disabled="disableSaveButton"
                    @click="handleSave"
                    size="small"
                    >保存
                </el-button>
            </el-card>

            <el-card shadow="never" style="margin-top: 8px">
                <el-table
                    border
                    stripe
                    style="width: 100%"
                    v-loading="tableLoading"
                    :data="form.detailParams"
                    size="mini"
                    :highlight-current-row="true"
                    max-height="450"
                >
                    <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                    <el-table-column label="商品编码" width="200px">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.goodsCode'"
                                :rules="rules.goodsCode"
                            >
                                {{ scope.row.goodsCode }}
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品名称">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="条码" width="120px">
                        <template slot-scope="scope">
                            {{
                                skuByGoodsCode(scope.row.goodsCode)
                                    .bars.map((d) => d)
                                    .join(' / ')
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column label="商品类型">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).type | skuTypeDesc }}
                        </template>
                    </el-table-column>
                    <el-table-column label="助记码">
                        <template slot-scope="scope">
                            {{ goodsByCode(scope.row.goodsCode).fastBar }}
                        </template>
                    </el-table-column>
                    <el-table-column label="自编码">
                        <template slot-scope="scope">
                            {{ goodsByCode(scope.row.goodsCode).customBar }}
                        </template>
                    </el-table-column>
                    <el-table-column label="规格">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).specs }}
                        </template>
                    </el-table-column>
                    <el-table-column label="类目">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).category }}
                        </template>
                    </el-table-column>
                    <el-table-column label="单位">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).unit }}
                        </template>
                    </el-table-column>
                    <el-table-column label="品牌">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).brand }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-if="hasPrivilege('biz.price.wholeSale.query') && showInPrice()"
                        label="批发价"
                        width="170"
                    >
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.showWholeSalePrices'"
                                :rules="rules.showWholeSalePrices"
                            >
                                <p :style="{ paddingTop: '16PX', fontSize: '12px', width: '200px', float: 'left' }">
                                    {{ scope.row.showWholeSalePrices }}
                                </p>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column v-if="hasPrivilege('biz.price.retail.query')" label="零售价" width="100">
                        <template slot-scope="scope">
                            {{ goodsRetailPrice(scope.row.goodsCode) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="单价" width="170" v-if="showInPrice()">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.showPrices'"
                                :rules="rules.showPrices"
                            >
                                <ef-price-input v-model="scope.row.showPrices" :precision="2" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="内购数量" width="170">
                        <template slot-scope="scope">
                            <el-form-item :prop="'detailParams.' + scope.$index + '.counts'" :rules="rules.counts">
                                <ef-stock-biz-count-input
                                    v-model="scope.row.counts"
                                    :sku-type="skuByGoodsCode(scope.row.goodsCode).type"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="80" header-align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click="handleDelete(scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </el-form>

        <pick-up-goods ref="pickupGoods" :selfCanClose="false" @queryGoods="setSelectedGoods" />
    </div>
</template>
<script>
import Util from 'js/Util';
import StockBizCommon, { PropertyConverter, wholeSalePrice } from 'js/StockBizCommon';
import PickUpGoods from 'components/PickUpGoods';
import EfRemark from 'components/EfRemark';
import EfStockBizCountInput from 'components/EfStockBizCountInput';
import GoodsCommon from 'js/GoodsCommon';
import {
    deptCodeValidateRule,
    goodsCodeValidateRule,
    pricesValidateRule,
    remarkNotRequiredValidateRule,
} from 'js/validate/ValidateCommonRule';
import {
    abstractCodeValidateRule,
    countGreaterThanZeroValidateRule,
    countValidateRule,
} from 'js/validate/ValidateStockBizRule';
import EfPriceInput from 'components/EfPriceInput';
import MoneyUtils from 'js/MoneyUtils';

const detailParamPropertyArr = ['codes', 'goodsCode', 'counts'];
export default {
    name: 'ReceiveEdit',
    props: {
        code: {
            type: String,
            required: true,
        },
    },
    components: { PickUpGoods, EfRemark, EfStockBizCountInput, EfPriceInput },
    data() {
        return {
            search: '',
            deptType: '',

            formExtend: {
                deptTypeName: '',
                createTime: '',
                creator: '',
                deptName: '',
                receiveName: '',
            },
            form: {
                deptCode: '',
                remark: '',
                detailParams: [],
                delCodes: [],
            },
            detailParamPropertyArrForShow: [
                ...detailParamPropertyArr,
                new PropertyConverter(null, 'showWholeSalePrices', (price, detailParam) => {
                    return MoneyUtils.moneyToYuan(detailParam.goodsDetail.wholeSalePrice);
                }),
                new PropertyConverter('prices', 'showPrices', (price) => {
                    return MoneyUtils.moneyToThree(price);
                }),
            ],
            detailParamPropertyArrForAdd: [
                ...detailParamPropertyArr,
                new PropertyConverter(wholeSalePrice, 'showWholeSalePrices', (price) => {
                    return MoneyUtils.moneyToYuan(price);
                }),
                new PropertyConverter(wholeSalePrice, 'showPrices', (price) => {
                    return MoneyUtils.moneyToThree(price);
                }),
            ],
            detailParamPropertyArrForSubmit: [
                ...detailParamPropertyArr,
                new PropertyConverter('showPrices', 'prices', (price) => {
                    return MoneyUtils.moneyToDb(price);
                }),
            ],

            goodsDetailMap: new Map(),
            rules: {
                deptCode: deptCodeValidateRule(),
                abstractCode: abstractCodeValidateRule(),
                remark: remarkNotRequiredValidateRule(),
                goodsCode: goodsCodeValidateRule(),
                showPrices: pricesValidateRule(),
                showWholeSalePrices: [{ required: true, message: '请选择批发价不为空的商品', trigger: ['change'] }],
                counts: [
                    countValidateRule(
                        () => (this.form || {}).detailParams || [],
                        (goodsCode) => this.skuByGoodsCode(goodsCode)
                    ),
                    countGreaterThanZeroValidateRule(),
                ],
            },
            tableLoading: true,
            saveLoading: false,
        };
    },
    mounted() {
        (async () => {
            //查询数据
            const rst = await this.$efApi.stockReceiveApi.detail(this.code);
            Util.copyProperties(rst, this.form);
            Util.copyProperties(rst, this.formExtend);
            const arr = await StockBizCommon.makeStockBizDetailGoodsAndParams(rst, this.detailParamPropertyArrForShow);
            this.goodsDetailMap = arr.goodsDetails;
            this.form.detailParams = arr.detailParams;
            this.tableLoading = false;
        })();
    },
    computed: {
        //禁用/启用按钮
        disableQueryAndAddButton() {
            const deptCode = this.form.deptCode;
            const search = this.search;
            return deptCode == null || deptCode === '' || search == null || search === '';
        },
        //禁用/启用按钮
        disableShowPickUpGoodsDialog() {
            const deptCode = this.form.deptCode;
            return deptCode == null || deptCode === '';
        },
        //禁用/启用按钮
        disableSaveButton() {
            const deptCode = this.form.deptCode;
            const detailParams = this.form.detailParams;
            return deptCode == null || deptCode === '' || detailParams == null || detailParams.length === 0;
        },
    },
    methods: {
        handleSave() {
            this.$refs.editForm.validate(async (valid) => {
                if (!valid) {
                    this.$message.error('填写内容有误，请检查所填写的所有数据');
                    return false;
                }
                if (this.form.detailParams.length > 150) {
                    this.$message.error('每笔单据做多添加150个商品，如想添加更多商品，请拆分为多条单据');
                    return false;
                }
                this.saveLoading = true;
                //将商品详情列表转换成多字段数组形式
                const updateParam = {
                    ...this.form,
                    ...StockBizCommon.convertStockBizDetailParamsToParamArrObj(
                        this.form.detailParams,
                        this.detailParamPropertyArrForSubmit
                    ),
                };
                //删除非提交必要字段数据
                delete updateParam.detailParams;
                await this.$efApi.stockReceiveApi.updateByFormData(this.code, updateParam).finally(() => {
                    this.saveLoading = false;
                });
                this.goBackAndReload();
            });
        },
        showPickUpGoods() {
            this.$refs.pickupGoods.show({ deptCode: this.form.deptCode }, this.formExtend.deptName);
        },
        async queryAndAdd() {
            const rst = await this.$efApi.goodsApi.queryGoods({ deptCode: this.form.deptCode, search: this.search });
            this.setSelectedGoods(rst.data);
        },
        handleDelete(index) {
            const detailCode = this.form.detailParams.splice(index, 1)[0].codes;
            if (Util.isEmpty(detailCode)) {
                //不存在，说明是单据详情中新增的商品被删除，则不需要处理
                return;
            }
            this.form.delCodes.push(detailCode);
        },
        setSelectedGoods(selectedGoods) {
            const oldDetails = Object.assign([], this.form.detailParams || []);
            StockBizCommon.addPickupGoodsToStockBizDetails(
                selectedGoods,
                this.goodsDetailMap,
                oldDetails,
                this.detailParamPropertyArrForAdd
            );
            if (oldDetails.length <= 150) {
                this.form.detailParams = oldDetails;
                this.$refs.pickupGoods.dialogFormVisible = false;
            } else {
                this.$message.warning('每笔单据做多添加150个商品，如想添加更多商品，请拆分为多条单据');
            }
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
        goodsRetailPrice(goodsCode) {
            return MoneyUtils.moneyToYuan(this.goodsByCode(goodsCode).retailPrice);
        },
        goodsWholeSalePrice(goodsCode) {
            return MoneyUtils.moneyToYuan(this.goodsByCode(goodsCode).wholeSalePrice);
        },
    },
    filters: {
        skuTypeDesc(type) {
            return GoodsCommon.skuTypeDesc(type);
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
